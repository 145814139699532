/**
 * Created by vaibhav on 2/4/18
 */
import React, { Component } from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import Helmet from "react-helmet"
import Link from "gatsby-link"
import PDF from "react-pdf-js-infinite"
import IRPageWrapper from "../../../../layouts/IRPageWrapper"
import IRNav from "../../../../components/IRNav"
import IRBG from "../../../../components/IRBG"
import Properties2015 from "./properties-2015.pdf"
import PropertiesNav from "../../../../components/PropertiesNav"
import HomeLogo from "../../../../components/HomeLogo"

class Properties2015Page extends Component {
  render() {
    return (
      <IRPageWrapper>
        <IRBG />
        <Helmet>
          <script src="/js/pdfobject.js" />
          <script>PDFObject.embed("/pdf/sample-3pp.pdf", "#example1");</script>
          <title>General Corporate Information</title>
        </Helmet>
        <section className="hero is-small">
          <div className="hero-body">
            <div className="container">
              <div className="columns">
                <div className="column">
                  <div className="section">
                    <HomeLogo />
                    <h1 className="title" id="title-line">
                      Our Properties
                    </h1>
                    <IRNav />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="section">
          <PropertiesNav />

          <div id="pdf-scroll">
            <PDF file={Properties2015} scale={1.5} />
          </div>
        </section>
      </IRPageWrapper>
    )
  }
}

Properties2015Page.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
}

export default Properties2015Page
